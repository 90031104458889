
export default [
	{
		num: '0',
		chapter: '0',
		level: '0',
		name: 'Dead Simple',
		partimeMMSS: '01:39',
		partimeSec: '99',
		partimeMs: '5940',
		filename: 'lv0_1',
		desc: 'Kill all undead.',
	},
	{
		num: '1',
		chapter: '1',
		level: '1',
		name: 'Noisy Neighbours',
		partimeMMSS: '00:23',
		partimeSec: '23',
		partimeMs: '1380',
		filename: 'lv1_1',
		desc: 'Kill all undead.',
	},
	{
		num: '2',
		chapter: '1',
		level: '2',
		name: 'New High',
		partimeMMSS: '00:35',
		partimeSec: '35',
		partimeMs: '2100',
		filename: 'lv1_2',
		desc: 'The zombies are selling some strange drug. Find out what it is.',
	},
	{
		num: '3',
		chapter: '1',
		level: '3',
		name: 'Smoke Signals',
		partimeMMSS: '00:53',
		partimeSec: '53',
		partimeMs: '3180',
		filename: 'lv1_3',
		desc: 'Let\'s get the attention of their leader. Burn all their drugs.',
	},
	{
		num: '4',
		chapter: '1',
		level: '4',
		name: 'Puff',
		partimeMMSS: '00:48',
		partimeSec: '48',
		partimeMs: '2880',
		filename: 'lv1_4',
		desc: 'A low level dealer, Puff, might know about their supplier. Kill him.',
	},
	{
		num: '5',
		chapter: '1',
		level: '5',
		name: 'Follow The Hounds',
		partimeMMSS: '00:48',
		partimeSec: '48',
		partimeMs: '2880',
		filename: 'lv1_5',
		desc: 'The first address leads to a Hellhound pound. End it.',
	},
	{
		num: '6',
		chapter: '1',
		level: '6',
		name: 'The Bogeyman',
		partimeMMSS: '00:50',
		partimeSec: '50',
		partimeMs: '3000',
		filename: 'lv1_6',
		desc: 'The second address leads to a house with a missing Candle.',
	},
	{
		num: '7',
		chapter: '1',
		level: '7',
		name: 'No Vacancy',
		partimeMMSS: '01:33',
		partimeSec: '93',
		partimeMs: '5580',
		filename: 'lv1_7',
		desc: 'Find the location of Roland\'s warehouse.',
	},
	{
		num: '8',
		chapter: '1',
		level: '8',
		name: 'Supply and Demand',
		partimeMMSS: '01:06',
		partimeSec: '66',
		partimeMs: '3960',
		filename: 'lv1_8',
		desc: 'Destroy the warehouse where the Zombie Kingz store their drugs.',
	},
	{
		num: '9',
		chapter: '1',
		level: '9',
		name: 'Roland',
		partimeMMSS: '00:42',
		partimeSec: '42',
		partimeMs: '2520',
		filename: 'lv1_9',
		desc: 'Kill Roland, the leader of the Zombie Kingz.',
	},
	{
		num: '10',
		chapter: '2',
		level: '1',
		name: 'Night Out',
		partimeMMSS: '00:58',
		partimeSec: '58',
		partimeMs: '3480',
		filename: 'lv2_1',
		desc: 'The vampires are running a club - find out why.',
	},
	{
		num: '11',
		chapter: '2',
		level: '2',
		name: 'Lux in Tenebris',
		partimeMMSS: '01:12',
		partimeSec: '72',
		partimeMs: '4320',
		filename: 'lv2_2',
		desc: 'The vampires\' numbers are worrying - thin it out.',
	},
	{
		num: '12',
		chapter: '2',
		level: '3',
		name: 'The Bloody Mary',
		partimeMMSS: '00:47',
		partimeSec: '47',
		partimeMs: '2820',
		filename: 'lv2_3',
		desc: 'Another club, another safe - maybe this one will have useful information.',
	},
	{
		num: '13',
		chapter: '2',
		level: '4',
		name: 'Bottle Service',
		partimeMMSS: '01:23',
		partimeSec: '83',
		partimeMs: '4980',
		filename: 'lv2_4',
		desc: 'What are the vampires doing near the docks?',
	},
	{
		num: '14',
		chapter: '2',
		level: '5',
		name: 'Amber & Evelynn',
		partimeMMSS: '01:20',
		partimeSec: '80',
		partimeMs: '4800',
		filename: 'lv2_5',
		desc: 'Twin vampires are running a brothel. Shut it down.',
	},
	{
		num: '15',
		chapter: '2',
		level: '6',
		name: 'A Lot To Give',
		partimeMMSS: '01:22',
		partimeSec: '82',
		partimeMs: '4920',
		filename: 'lv2_6',
		desc: 'This club is strangely quiet - what happened?',
	},
	{
		num: '16',
		chapter: '2',
		level: '7',
		name: 'Sir Stela',
		partimeMMSS: '01:11',
		partimeSec: '71',
		partimeMs: '4260',
		filename: 'lv2_7',
		desc: 'The vampire leader must know about what happened.',
	},
	{
		num: '17',
		chapter: '2',
		level: '8',
		name: 'Forced Conversion',
		partimeMMSS: '00:56',
		partimeSec: '56',
		partimeMs: '3360',
		filename: 'lv2_8',
		desc: 'The vampires are being turned to ash - stop the next harvest.',
	},
	{
		num: '18',
		chapter: '2',
		level: '9',
		name: 'Madam Stela',
		partimeMMSS: '00:52',
		partimeSec: '52',
		partimeMs: '3120',
		filename: 'lv2_9',
		desc: 'She knows about the conversion. Kill her and stop the ash.',
	},
	{
		num: '19',
		chapter: '3',
		level: '1',
		name: 'Overnight Shipping',
		partimeMMSS: '01:50',
		partimeSec: '110',
		partimeMs: '6600',
		filename: 'lv3_1',
		desc: 'The skeletons have been congregating near the docks. Find out why.',
	},
	{
		num: '20',
		chapter: '3',
		level: '2',
		name: 'Structurally Sound',
		partimeMMSS: '00:54',
		partimeSec: '54',
		partimeMs: '3240',
		filename: 'lv3_2',
		desc: 'They\'ve been attempting to build a new base. Destroy its inhabitants.',
	},
	{
		num: '21',
		chapter: '3',
		level: '3',
		name: 'Bar Hopping',
		partimeMMSS: '00:48',
		partimeSec: '48',
		partimeMs: '2880',
		filename: 'lv3_3_1',
		desc: 'Two bars, back to back. Eliminate both.',
	},
	{
		num: '22',
		chapter: '3',
		level: '4',
		name: 'Horror Show',
		partimeMMSS: '01:19',
		partimeSec: '79',
		partimeMs: '4740',
		filename: 'lv3_4',
		desc: 'The Candles have found the shipping location..',
	},
	{
		num: '23',
		chapter: '3',
		level: '5',
		name: 'Timur The Tinkerer',
		partimeMMSS: '01:11',
		partimeSec: '71',
		partimeMs: '4260',
		filename: 'lv3_5',
		desc: 'Timur is a high ranking Dredged. I\'ll find out what he knows.',
	},
	{
		num: '24',
		chapter: '3',
		level: '6',
		name: 'Drive Thru',
		partimeMMSS: '01:04',
		partimeSec: '64',
		partimeMs: '3840',
		filename: 'lv3_6',
		desc: 'I have to find out where Ibzan, The Dredged leader, lives.',
	},
	{
		num: '25',
		chapter: '3',
		level: '7',
		name: 'As A Glass',
		partimeMMSS: '01:41',
		partimeSec: '101',
		partimeMs: '6060',
		filename: 'lv3_7',
		desc: 'Ibzan\'s home is heavily guarded, but must contain important information.',
	},
	{
		num: '26',
		chapter: '3',
		level: '8',
		name: 'Vall',
		partimeMMSS: '01:57',
		partimeSec: '117',
		partimeMs: '7020',
		filename: 'lv3_8',
		desc: 'I need snipers on the roof - but must get rid of the Vall first.',
	},
	{
		num: '27',
		chapter: '3',
		level: '9',
		name: 'Ibzan',
		partimeMMSS: '03:37',
		partimeSec: '217',
		partimeMs: '13020',
		filename: 'lv3_9',
		desc: 'I have to stop Ibzan and The Dredged from leaving this Place.',
	},
]
