<template>
	<div class="intro-content centered-content">
		<p>Use this tool to calculate your star rank.</p>
		<p>There are tips below, and a table showing all par times.</p>
		<p><span class="color-teal">Tip:</span> Click an input field and use the arrow keys<br>to make quick changes.</p>
	</div>
</template>

<script>
	export default {
		name: 'CalcIntro'
	}
</script>

<style lang="scss" scoped>
	.intro-content {
		text-align: center;
		margin: 40px auto 40px;

		p {
			font-size: 1.4rem;
			margin-bottom: 20px;
		}
	}
</style>
