<template>
	<div>
		<p>Details of the <span class="color-g">par times</span> for each level.</p>

		<p>The <span class="color-r">slowest time</span> are also shown. This is the slowest time allowed if your <span class="color-purple">shooting%</span> totals 150 or higher. One second over this (or 1% lower in your shooting%) and you'll lose your 5 stars.</p>

		<TableSimple :rows="rows" :columns="columns"/>
	</div>
</template>

<script>
	import TableSimple from '@/components/TableSimple'

	export default {
		name: 'SettingsCustom',
		components: {
			TableSimple
		},
		data() {
			return {
				columns: {
					col1: '#',
					col2: 'Ch',
					col3: 'Lvl',
					col4: 'Name',
					col5: 'Par Time',
					col6: 'Slowest Time',
					col7: 'Filename',
					col8: 'Description',
				},
				rows: [
					{
						col1: '0',
						col2: '0',
						col3: '0',
						col4: 'Dead Simple',
						col5: '<span class="color-g font-mono-numbers">01:39</span>',
						col6: '<span class="color-r font-mono-numbers">04:35</span>',
						col7: 'lv0_1',
						col8: 'Kill all undead.',
					},
					{
						col1: '1',
						col2: '1',
						col3: '1',
						col4: 'Noisy Neighbours',
						col5: '<span class="color-g font-mono-numbers">00:23</span>',
						col6: '<span class="color-r font-mono-numbers">01:03</span>',
						col7: 'lv1_1',
						col8: 'Kill all undead.',
					},
					{
						col1: '2',
						col2: '1',
						col3: '2',
						col4: 'New High',
						col5: '<span class="color-g font-mono-numbers">00:35</span>',
						col6: '<span class="color-r font-mono-numbers">01:37</span>',
						col7: 'lv1_2',
						col8: 'The zombies are selling some strange drug. Find out what it is.',
					},
					{
						col1: '3',
						col2: '1',
						col3: '3',
						col4: 'Smoke Signals',
						col5: '<span class="color-g font-mono-numbers">00:53</span>',
						col6: '<span class="color-r font-mono-numbers">02:27</span>',
						col7: 'lv1_3',
						col8: 'Let\'s get the attention of their leader. Burn all their drugs.',
					},
					{
						col1: '4',
						col2: '1',
						col3: '4',
						col4: 'Puff',
						col5: '<span class="color-g font-mono-numbers">00:48</span>',
						col6: '<span class="color-r font-mono-numbers">02:13</span>',
						col7: 'lv1_4',
						col8: 'A low level dealer, Puff, might know about their supplier. Kill him.',
					},
					{
						col1: '5',
						col2: '1',
						col3: '5',
						col4: 'Follow The Hounds',
						col5: '<span class="color-g font-mono-numbers">00:48</span>',
						col6: '<span class="color-r font-mono-numbers">02:13</span>',
						col7: 'lv1_5',
						col8: 'The first address leads to a Hellhound pound. End it.',
					},
					{
						col1: '6',
						col2: '1',
						col3: '6',
						col4: 'The Bogeyman',
						col5: '<span class="color-g font-mono-numbers">00:50</span>',
						col6: '<span class="color-r font-mono-numbers">02:18</span>',
						col7: 'lv1_6',
						col8: 'The second address leads to a house with a missing Candle.',
					},
					{
						col1: '7',
						col2: '1',
						col3: '7',
						col4: 'No Vacancy',
						col5: '<span class="color-g font-mono-numbers">01:33</span>',
						col6: '<span class="color-r font-mono-numbers">04:18</span>',
						col7: 'lv1_7',
						col8: 'Find the location of Roland\'s warehouse.',
					},
					{
						col1: '8',
						col2: '1',
						col3: '8',
						col4: 'Supply and Demand',
						col5: '<span class="color-g font-mono-numbers">01:06</span>',
						col6: '<span class="color-r font-mono-numbers">03:03</span>',
						col7: 'lv1_8',
						col8: 'Destroy the warehouse where the Zombie Kingz store their drugs.',
					},
					{
						col1: '9',
						col2: '1',
						col3: '9',
						col4: 'Roland',
						col5: '<span class="color-g font-mono-numbers">00:42</span>',
						col6: '<span class="color-r font-mono-numbers">01:56</span>',
						col7: 'lv1_9',
						col8: 'Kill Roland, the leader of the Zombie Kingz.',
					},
					{
						col1: '10',
						col2: '2',
						col3: '1',
						col4: 'Night Out',
						col5: '<span class="color-g font-mono-numbers">00:58</span>',
						col6: '<span class="color-r font-mono-numbers">02:41</span>',
						col7: 'lv2_1',
						col8: 'The vampires are running a club - find out why.',
					},
					{
						col1: '11',
						col2: '2',
						col3: '2',
						col4: 'Lux in Tenebris',
						col5: '<span class="color-g font-mono-numbers">01:12</span>',
						col6: '<span class="color-r font-mono-numbers">03:20</span>',
						col7: 'lv2_2',
						col8: 'The vampires\' numbers are worrying - thin it out.',
					},
					{
						col1: '12',
						col2: '2',
						col3: '3',
						col4: 'The Bloody Mary',
						col5: '<span class="color-g font-mono-numbers">00:47</span>',
						col6: '<span class="color-r font-mono-numbers">02:10</span>',
						col7: 'lv2_3',
						col8: 'Another club, another safe - maybe this one will have useful information.',
					},
					{
						col1: '13',
						col2: '2',
						col3: '4',
						col4: 'Bottle Service',
						col5: '<span class="color-g font-mono-numbers">01:23</span>',
						col6: '<span class="color-r font-mono-numbers">03:50</span>',
						col7: 'lv2_4',
						col8: 'What are the vampires doing near the docks?',
					},
					{
						col1: '14',
						col2: '2',
						col3: '5',
						col4: 'Amber & Evelynn',
						col5: '<span class="color-g font-mono-numbers">01:20</span>',
						col6: '<span class="color-r font-mono-numbers">03:42</span>',
						col7: 'lv2_5',
						col8: 'Twin vampires are running a brothel. Shut it down.',
					},
					{
						col1: '15',
						col2: '2',
						col3: '6',
						col4: 'A Lot To Give',
						col5: '<span class="color-g font-mono-numbers">01:22</span>',
						col6: '<span class="color-r font-mono-numbers">03:47</span>',
						col7: 'lv2_6',
						col8: 'This club is strangely quiet - what happened?',
					},
					{
						col1: '16',
						col2: '2',
						col3: '7',
						col4: 'Sir Stela',
						col5: '<span class="color-g font-mono-numbers">01:11</span>',
						col6: '<span class="color-r font-mono-numbers">03:17</span>',
						col7: 'lv2_7',
						col8: 'The vampire leader must know about what happened.',
					},
					{
						col1: '17',
						col2: '2',
						col3: '8',
						col4: 'Forced Conversion',
						col5: '<span class="color-g font-mono-numbers">00:56</span>',
						col6: '<span class="color-r font-mono-numbers">02:35</span>',
						col7: 'lv2_8',
						col8: 'The vampires are being turned to ash - stop the next harvest.',
					},
					{
						col1: '18',
						col2: '2',
						col3: '9',
						col4: 'Madam Stela',
						col5: '<span class="color-g font-mono-numbers">00:52</span>',
						col6: '<span class="color-r font-mono-numbers">02:24</span>',
						col7: 'lv2_9',
						col8: 'She knows about the conversion. Kill her and stop the ash.',
					},
					{
						col1: '19',
						col2: '3',
						col3: '1',
						col4: 'Overnight Shipping',
						col5: '<span class="color-g font-mono-numbers">01:50</span>',
						col6: '<span class="color-r font-mono-numbers">05:05</span>',
						col7: 'lv3_1',
						col8: 'The skeletons have been congregating near the docks. Find out why.',
					},
					{
						col1: '20',
						col2: '3',
						col3: '2',
						col4: 'Structurally Sound',
						col5: '<span class="color-g font-mono-numbers">00:54</span>',
						col6: '<span class="color-r font-mono-numbers">02:30</span>',
						col7: 'lv3_2',
						col8: 'They\'ve been attempting to build a new base. Destroy its inhabitants.',
					},
					{
						col1: '21',
						col2: '3',
						col3: '3',
						col4: 'Bar Hopping',
						col5: '<span class="color-g font-mono-numbers">00:48</span>',
						col6: '<span class="color-r font-mono-numbers">02:13</span>',
						col7: 'lv3_3_1',
						col8: 'Two bars, back to back. Eliminate both.',
					},
					{
						col1: '22',
						col2: '3',
						col3: '4',
						col4: 'Horror Show',
						col5: '<span class="color-g font-mono-numbers">01:19</span>',
						col6: '<span class="color-r font-mono-numbers">03:39</span>',
						col7: 'lv3_4',
						col8: 'The Candles have found the shipping location..',
					},
					{
						col1: '23',
						col2: '3',
						col3: '5',
						col4: 'Timur The Tinkerer',
						col5: '<span class="color-g font-mono-numbers">01:11</span>',
						col6: '<span class="color-r font-mono-numbers">03:37</span>',
						col7: 'lv3_5',
						col8: 'Timur is a high ranking Dredged. I\'ll find out what he knows.',
					},
					{
						col1: '24',
						col2: '3',
						col3: '6',
						col4: 'Drive Thru',
						col5: '<span class="color-g font-mono-numbers">01:04</span>',
						col6: '<span class="color-r font-mono-numbers">02:57</span>',
						col7: 'lv3_6',
						col8: 'I have to find out where Ibzan, The Dredged leader, lives.',
					},
					{
						col1: '25',
						col2: '3',
						col3: '7',
						col4: 'As A Glass',
						col5: '<span class="color-g font-mono-numbers">01:41</span>',
						col6: '<span class="color-r font-mono-numbers">04:40</span>',
						col7: 'lv3_7',
						col8: 'Ibzan\'s home is heavily guarded, but must contain important information.',
					},
					{
						col1: '26',
						col2: '3',
						col3: '8',
						col4: 'Vall',
						col5: '<span class="color-g font-mono-numbers">01:57</span>',
						col6: '<span class="color-r font-mono-numbers">05:25</span>',
						col7: 'lv3_8',
						col8: 'I need snipers on the roof - but must get rid of the Vall first.',
					},
					{
						col1: '27',
						col2: '3',
						col3: '9',
						col4: 'Ibzan',
						col5: '<span class="color-g font-mono-numbers">03:37</span>',
						col6: '<span class="color-r font-mono-numbers">10:02</span>',
						col7: 'lv3_9',
						col8: 'I have to stop Ibzan and The Dredged from leaving this Place.',
					},
				],
			}
		}
	}
</script>
